import * as React from "react";
import styles from "./Header.module.scss";
import LazyProductImage from "../../components/lazy-product-image/lazy-product-image";
import { PLAUSIBLE_DOMAIN } from "../../config";
import { getLang } from "../../utils/lang";
import { getUserAgent } from "../../utils/agent";
import { trackEventSegment } from "../../api";
import { IUserAgent } from "../../types/ua";
import Link from "next/link";
// tslint:disable-next-line:cognitive-complexity
export function HeaderNewComponent(props: {
  variant?: "beige" | "beige-center";
  showSlogan?: boolean;
  hideBtns?: boolean;
  disableScroll?: boolean;
  language?: string;
  store: string;
}) {
  const [hasScrolled, setScrolled] = React.useState(false);
  const [userAgent, setUserAgent] = React.useState<IUserAgent>(
    {} as IUserAgent
  );
  React.useEffect(() => {
    const ua = getUserAgent();
    setUserAgent(ua);
    if (!props.disableScroll) {
      const isBrowser = typeof window !== `undefined`;
      if (isBrowser) {
        const handleScroll = () => {
          setScrolled(window.scrollY > 56);
        };

        window.addEventListener("scroll", handleScroll);
        setScrolled(window.scrollY > 56);
        return () => window.removeEventListener("scroll", handleScroll);
      }
    }
  }, [props.disableScroll]);

  function onClickApple() {
    trackEventSegment("clickApple", {
      store: props.store,
      lang: props.language,
    });
  }

  function onclickAndroid() {
    trackEventSegment("clickAndroid", {
      store: props.store,
      lang: props.language,
    });
  }

  const className = `${styles.header} ${styles[props.variant || ""] || ""} ${
    hasScrolled ? styles.scroll : ""
  } ${props.disableScroll ? styles["no-scroll"] : ""}`.trim();
  const className2 = `${styles.column} col-6 col-sm-12 ${
    props.showSlogan ? styles.hasSlogan : ""
  }`.trim();

  const getHomeUrl = () => {
    if (PLAUSIBLE_DOMAIN === "appchoose.co") {
      const store = props.store === "fr" ? "eu" : "us";
      return `/${props.language ?? "fr"}/${store}`;
    }
    return "/";
  };

  return (
    <header
      className={className}
      style={{
        boxShadow: !hasScrolled || props.disableScroll ? "none" : undefined,
      }}
    >
      <div className="container align-items-center">
        <div className={`${styles.container} row`}>
          <div className={className2}>
            <Link
              href={{
                pathname: getHomeUrl(),
                query: {
                  store: props.store,
                  language: props.language,
                },
              }}
            >
              <img
                alt="Logo-new"
                src="https://public.choose.app/website/new/images/Logo-new.png"
                srcSet={`https://public.choose.app/website/new/images/Logo-new@2x.png 2x,https://public.choose.app/website/new/images/Logo-new@3x.png 3x`}
              />
            </Link>
            {props.showSlogan ? (
              <p className={styles.slogan}>
                Your daily drop of <i>cool</i>
              </p>
            ) : null}
          </div>
          {/* {i18n.language} */}
          {hasScrolled || (props.disableScroll && !props.hideBtns) ? (
            <div className={`animated fadeIn ${styles.stores} col-6`}>
              {(userAgent.isMobile && !userAgent.isAndroid) ||
              !userAgent.isMobile ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginRight: 16 }}
                  onClick={onClickApple}
                  title="Apple Store"
                  className="plausible-event-name=Download+App plausible-event-platform=apple"
                  href="https://apps.apple.com/fr/app/choose-concept-store/id985799982?mt=8&ct=website"
                >
                  <LazyProductImage
                    width="134"
                    alt="Apple Store"
                    height="40"
                    src={`https://public.choose.app/website/new/images/btn-small-app-store-${getLang()}.svg`}
                  />
                </a>
              ) : null}
              {(userAgent.isMobile && userAgent.isAndroid) ||
              !userAgent.isMobile ? (
                <a
                  onClick={onclickAndroid}
                  href="https://play.google.com/store/apps/details?id=com.appchoose.choose.android&hl=fr&gl=FR"
                  rel="noopener noreferrer"
                  className="plausible-event-name=Download+App plausible-event-platform=playstore"
                  target="_blank"
                >
                  <LazyProductImage
                    height="41"
                    width="136"
                    alt="Play Store"
                    src={`https://public.choose.app/website/new/images/btn-small-play-store-${getLang()}.svg`}
                  />
                </a>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </header>
  );
}
